import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/market.png";
import emotion from "../../Assets/Projects/wp1.png";
import editor from "../../Assets/Projects/mustafaalali.ch.png";
import person from "../../Assets/Projects/personal1.png";
import templatphoto from "../../Assets/Projects/react-template.png";
import suicide from "../../Assets/Projects/tailwind.png";
import bitsOfCode from "../../Assets/Projects/mustafa-alali.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meine letzten <strong className="purple">Projekte </strong>
        </h1>
        <h4 style={{ color: "white" }}>
          Hier sind einige Projekte, an denen ich in letzter Zeit gearbeitet
          habe.
        </h4>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={templatphoto}
              isBlog={false}
              title="React-Template"
              description=" Website, Ein Modell zum Erstellen einer Gaming-Website mit VITE+ React 18"
              /* ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/" */
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Persönliche Webseite"
              description="Meine persönliche Blog-Seite wurde mit Next.js und Tailwind Css erstellt."
              /*  ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/" */
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={person}
              isBlog={false}
              title="Passagier"
              description=" Website, die dem Reisenden Dienstleistungen wie Reservierungen, Touristenorte, Hotels und Autos bietet build with react, Material-UI, and Firebase."
              /* ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/" */
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Beratung und Betreuung für Migrant:innen website."
              description="Design und Programmierung. build with HTML/CSS/JS/PHP code with instant view of website."
              /* demoLink="https://editor.soumya-jit.tech/"    */
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Market-Demo"
              description="Shopping-Website zum Online-Anzeigen und Verkaufen von Produkten."
              /* ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/" */
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Portfolio"
              description="Mit Tailwind erstelle ich innerhalb eines Tages Ihre eigene Website."
              /*  ghLink="https://github.com/soumyajit4419/AI_For_Social_Good" */
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Portfolio"
              description="Gestalte Ihre persönliche Website mit WordPress online ohne Programmierung"
              /* ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection" */
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import Card from "react-bootstrap/Card";
/* import { ImPointRight } from "react-icons/im"; */

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Mustafa Alali </span>
            from <span className="purple"> Syria.</span>
            <br />
            I love to code. good planning, structured thinking and perseverance are my strengths to achieve goals.
            <br />
            I am also always willing to continue my education.
            I acquired application-oriented knowledge and specialist skills in front-end and back-end engineering and the development of web applications.
            This makes me fit for software engineering and project management as well as in the core technologies of user interface frameworks and mobile systems, 
            in addition to working with software architectures including cloud computing, operating systems and databases.
            <br />
            <br />
            "What particularly sets me apart is my enjoyment of modern technologies and my willingness to continually educate myself. 
            The IT industry is constantly changing and I see it as my responsibility to keep pace and continually expand my knowledge 
            to ensure I am able to deliver world-class solutions."
            <br />
            <br />
            My favorite books: "Zen Mind, Beginner's Mind" | "Stranger in a Strange Land Robert A. Heinlein".
           
          </p>
          

          <p style={{ color: "rgb(155 126 172)" }}>
          In the beginner's mind there are many possibilities, <p>but in the expert’s, there are few." 😄</p>
          </p>
          <footer className="blockquote-footer">Mustafa</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

import React from 'react'
import { Container,Row,Col } from "react-bootstrap";
import Particle from "../Particle";
import myImg from "../../Assets/home2.svg";
import Tilt from "react-parallax-tilt";
import Form from 'react-bootstrap/Form';
function Contact() {
  return (
    <section>
        <Container fluid className="about-section">
      <Particle />
      <Container>
       
        <h1 className="project-heading">
        My <strong className="purple"> contact form</strong>
        </h1>

        <Row>
          
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
      
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>textarea</Form.Label>
                <Form.Control as="textarea" rows={3} />
            </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
    </section>
  )
}

export default Contact

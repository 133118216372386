import React from "react";
import { Container } from "react-bootstrap";

import Particle from "../Particle";
import Techstack from "../About/Techstack";

function Skills() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
      <h1 className="project-heading">
        Meine <strong className="purple">Fähigkeiten </strong>
        </h1>

        <Techstack />
      </Container>
    </Container>
  );
}

export default Skills;
